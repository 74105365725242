<template>
  <div v-if="jobAssignmentOrdersData" class="custom-tab-card">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("employeeName") }}</th>
            <th>{{ $t("jobAssignmentOrders.code") }}</th>
            <th>{{ $t("jobAssignmentOrders.name") }}</th>
            <th>{{ $t("jobAssignmentOrders.description") }}</th>
            <th>{{ $t("jobAssignmentOrders.dateTimeStart") }}</th>
            <th>{{ $t("jobAssignmentOrders.dateTimeEnd") }}</th>
            <th>{{ $t("jobAssignmentOrders.assignmentDuration") }}</th>

            <th>{{ $t("info") }}</th>
            <th>
              {{ $t("edit") }}
            </th>
            <th>{{ $t("jobAssignmentOrders.print") }}</th>
            <th>
              {{ $t("delete") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(jobAssignmentOrder, index) in jobAssignmentOrdersData"
            :key="jobAssignmentOrder.jobAssignmentOrderToken"
          >
            <td>{{ ++index + pagination.currentIndex }}</td>
            <td>
              {{ isDataExist(jobAssignmentOrder.employeeNameCurrent) }}
            </td>
            <td>
              {{ isDataExist(jobAssignmentOrder.jobAssignmentOrderCode) }}
            </td>
            <td>
              <span class="cut-3line">
                {{
                  isDataExist(jobAssignmentOrder.jobAssignmentOrderNameCurrent)
                }}
              </span>
            </td>
            <td>
              <span class="cut-3line">
                {{
                  isDataExist(
                    jobAssignmentOrder.jobAssignmentOrderDescriptionCurrent
                  )
                }}
              </span>
            </td>

            <td>
              {{
                isDataExist(
                  formateDateTimeLang(
                    jobAssignmentOrder.employeeJobAssignmentOrderStartDate,
                    jobAssignmentOrder.employeeJobAssignmentOrderStartTime
                  )
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  formateDateTimeLang(
                    jobAssignmentOrder.employeeJobAssignmentOrderEndDate,
                    jobAssignmentOrder.employeeJobAssignmentOrderEndTime
                  )
                )
              }}
            </td>
            <td>
              {{
                isDataExist(
                  jobAssignmentOrder.employeeJobAssignmentOrderDuration
                )
              }}
            </td>
            <td>
              <button
                v-b-modal.JobAssignmentOrderInfo
                class="btn p-0"
                :title="$t('info')"
                @click="setJobAssignmentOrderData(jobAssignmentOrder)"
              >
                <img src="@/assets/images/info.svg" class="icon-lg" />
              </button>
            </td>
            <td>
              <router-link
                :to="{
                  name: 'JobAssignmentOrderEdit',
                  params: {
                    jobAssignmentOrderToken:
                      jobAssignmentOrder.jobAssignmentOrderToken,
                  },
                }"
                :title="$t('edit')"
              >
                <img src="@/assets/images/pencil.svg" class="icon-lg" />
              </router-link>
            </td>
            <td>
              <button
                v-b-modal.JobAssignmentOrderPrint
                class="btn p-0"
                :title="$t('jobAssignmentOrders.print')"
                @click="setJobAssignmentOrderData(jobAssignmentOrder)"
              >
                <img src="@/assets/images/contracts.svg" class="icon-lg" />
              </button>
            </td>
            <td>
              <button
                v-b-modal.JobAssignmentOrderDelete
                class="btn p-0"
                :title="$t('delete')"
                @click="setJobAssignmentOrderData(jobAssignmentOrder)"
              >
                <img src="@/assets/images/trash.svg" class="icon-lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  setDataMultiLang,
  formateDateTimeLang,
  isDataExist,
} from "@/utils/functions";
import privilegeMixin from "@/utils/privilege-mixin";

export default {
  name: "JobAssignmentOrdersTable",
  mixins: [privilegeMixin],
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["jobAssignmentOrdersData", "jobAssignmentOrderData", "pagination"],
  methods: {
    setDataMultiLang,
    formateDateTimeLang,
    isDataExist,
    setJobAssignmentOrderData(jobAssignmentOrderData) {
      this.$emit("setJobAssignmentOrderData", jobAssignmentOrderData);
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
