<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div
      v-if="
        jobAssignmentOrders.jobAssignmentOrdersData != undefined &&
        jobAssignmentOrders.jobAssignmentOrdersData.length != 0
      "
    >
      <JobAssignmentOrdersTable
        :jobAssignmentOrdersData="jobAssignmentOrders.jobAssignmentOrdersData"
        :jobAssignmentOrderData="jobAssignmentOrders.jobAssignmentOrder"
        v-on:setJobAssignmentOrderData="
          jobAssignmentOrders.jobAssignmentOrder.fillData($event)
        "
        :pagination="jobAssignmentOrders.pagination"
      />
      <JobAssignmentOrderInfo
        :jobAssignmentOrderData="jobAssignmentOrders.jobAssignmentOrder"
      />
      <JobAssignmentOrderDelete
        :jobAssignmentOrderData="jobAssignmentOrders.jobAssignmentOrder"
        v-on:refresh="getAllJobAssignmentOrders()"
      />
      <JobAssignmentOrderPrint
        :jobAssignmentOrderData="jobAssignmentOrders.jobAssignmentOrder"
      />
      <Pagination
        v-if="!isLoading"
        :paginationData="jobAssignmentOrders.pagination"
        v-on:changePage="changePage"
      />
    </div>

    <JobAssignmentOrderFloatBtns
      :theFilterData="jobAssignmentOrders.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import JobAssignmentOrdersTable from "@/components/employees/jobAssignmentOrders/JobAssignmentOrdersTable.vue";
import JobAssignmentOrderInfo from "@/components/employees/jobAssignmentOrders/JobAssignmentOrderInfo.vue";
import JobAssignmentOrderDelete from "@/components/employees/jobAssignmentOrders/JobAssignmentOrderDelete.vue";
import JobAssignmentOrderPrint from "@/components/employees/jobAssignmentOrders/JobAssignmentOrderPrint.vue";
import JobAssignmentOrderFloatBtns from "@/components/employees/jobAssignmentOrders/JobAssignmentOrderFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import currentTabMixin from "@/utils/currentTab-mixin";
import JobAssignmentOrders from "@/models/employees/jobAssignmentOrders/JobAssignmentOrders";

export default {
  name: "JobAssignmentOrders",
  mixins: [createToastMixin, currentTabMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    JobAssignmentOrdersTable,
    JobAssignmentOrderInfo,
    JobAssignmentOrderDelete,
    JobAssignmentOrderPrint,
    JobAssignmentOrderFloatBtns,
    Pagination,
  },
  computed: {
    ...mapGetters(["employeeToken"]),
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      jobAssignmentOrderToken: "",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      jobAssignmentOrders: new JobAssignmentOrders(),
    };
  },
  methods: {
    changePage(page) {
      this.jobAssignmentOrders.pagination.selfPage = page;
      this.getAllJobAssignmentOrders();
    },
    search(data) {
      this.jobAssignmentOrders.filterData.fillData(data);
      this.jobAssignmentOrders.jobAssignmentOrdersData = [];
      this.getAllJobAssignmentOrders();
    },
    async getAllJobAssignmentOrders() {
      this.isLoading = true;
      try {
        const response =
          await this.jobAssignmentOrders.jobAssignmentOrder.getAllJobAssignmentOrders(
            this.language,
            this.userAuthorizeToken,
            this.jobAssignmentOrders.pagination,
            this.jobAssignmentOrders.filterData
          );

        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.jobAssignmentOrders.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.jobAssignmentOrders.jobAssignmentOrdersData = [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.jobAssignmentOrders.jobAssignmentOrdersData = [];
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.jobAssignmentOrders.jobAssignmentOrdersData = [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.jobAssignmentOrders.jobAssignmentOrdersData = [];
        this.exceptionImg = "illustrator-somethingWrong.svg";
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  async created() {
    if (this.currentTab && this.employeeToken) {
      this.jobAssignmentOrders.filterData.employeeToken = this.employeeToken;
    }
    this.getAllJobAssignmentOrders();
  },
};
</script>
