import PaginationData from "@/models/general/PaginationData";
import JobAssignmentOrder from "./JobAssignmentOrder";
import JobAssignmentOrdersFilter from "./JobAssignmentOrdersFilter";

export default class JobAssignmentOrders {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.jobAssignmentOrdersData = [];
    this.jobAssignmentOrder = new JobAssignmentOrder();
    this.filterData = new JobAssignmentOrdersFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(data.jobAssignmentOrdersPagination);
    this.jobAssignmentOrdersData =
      data.jobAssignmentOrdersPagination.jobAssignmentOrdersData;
  }
}
