<template>
  <b-modal
    id="JobAssignmentOrderInfo"
    scrollable
    size="lg"
    centered
    hide-footer
  >
    <template #modal-title>
      <h3>
        <img src="@/assets/images/orders.svg" class="icon-lg" />
        {{ $t("jobAssignmentOrders.data") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="jobAssignmentOrderData.employeeNameCurrent"
        :title="$t('employeeName')"
        :imgName="'man.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="jobAssignmentOrderData.jobAssignmentOrderCode"
        :title="$t('jobAssignmentOrders.code')"
        :imgName="'number.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="jobAssignmentOrderData.jobAssignmentOrderNameAr"
        :title="$t('jobAssignmentOrders.nameAr')"
        :imgName="'orders.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="jobAssignmentOrderData.jobAssignmentOrderNameEn"
        :title="$t('jobAssignmentOrders.nameEn')"
        :imgName="'orders.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="jobAssignmentOrderData.jobAssignmentOrderNameEn"
        :title="$t('jobAssignmentOrders.nameEn')"
        :imgName="'orders.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="jobAssignmentOrderData.jobAssignmentOrderDescriptionAr"
        :title="$t('jobAssignmentOrders.descriptionAr')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="jobAssignmentOrderData.jobAssignmentOrderDescriptionEn"
        :title="$t('jobAssignmentOrders.descriptionEn')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="jobAssignmentOrderData.jobAssignmentOrderDescriptionUnd"
        :title="$t('jobAssignmentOrders.descriptionUnd')"
        :imgName="'description.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          formateDateTimeLang(
            jobAssignmentOrderData.employeeJobAssignmentOrderStartDate,
            jobAssignmentOrderData.employeeJobAssignmentOrderStartTime
          )
        "
        :title="$t('jobAssignmentOrders.dateTimeStart')"
        :imgName="'dateAndTime.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          formateDateTimeLang(
            jobAssignmentOrderData.employeeJobAssignmentOrderEndDate,
            jobAssignmentOrderData.employeeJobAssignmentOrderEndTime
          )
        "
        :title="$t('jobAssignmentOrders.dateTimeEnd')"
        :imgName="'dateAndTime.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="jobAssignmentOrderData.employeeJobAssignmentOrderDuration"
        :title="$t('jobAssignmentOrders.assignmentDuration')"
        :imgName="'time.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="jobAssignmentOrderData.employeeMakeActionNameCurrent"
        :title="$t('userMakeAction')"
        :imgName="'man.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          formateDateTimeLang(
            jobAssignmentOrderData.jobAssignmentOrderActionDate,
            jobAssignmentOrderData.jobAssignmentOrderActionTime
          )
        "
        :title="$t('actionDateTime')"
        :imgName="'dateAndTime.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="jobAssignmentOrderData.jobAssignmentOrderNotes"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import {
  setDataMultiLang,
  timeToLang,
  formateDateTimeLang,
} from "@/utils/functions";

export default {
  name: "JobAssignmentOrderInfo",
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["jobAssignmentOrderData"],
  methods: {
    formateDateTimeLang,
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      return setDataMultiLang(lang, dataAr, dataEn);
    },
  },
};
</script>

<style scoped lang="scss"></style>
